import { Col, Collapse, Container, Row } from "reactstrap";
import React, { useState } from "react";

const Faq = () => {
  const [question, setQuestion] = useState({
    primera: false,
    segunda: false,
    tercera: false,
    cuarta: false,
    quita: false,
    sexta: false,
  });

  return (
    <React.Fragment>
      <section className="section" id="services">
        <Container>
          <Row>
            <Col lg="12">
              <div className="title-box text-center">
                <h3 className="title-heading mt-2">Preguntas frecuentes</h3>
              </div>
            </Col>
          </Row>
          <Row className="mt-2 pt-4">
            <div
              id="accordion"
              style={{
                width: "100%",
              }}
            >
              <div className="faq">
                <div id="headingOne">
                  <h5 class="mb-0">
                    <button
                      className="btn colorButtonfaq"
                      onClick={() =>
                        setQuestion({ ...question, primera: !question.primera })
                      }
                    >
                      ¿La estructura metálica de la tina se calienta al punto de
                      quemar?
                    </button>
                  </h5>
                </div>

                <Collapse
                  isOpen={question.primera}
                  class="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    No. Incluso, la tina adquire una temperatura menor o similar
                    a la del agua. Sólo se debe tener precaución con la caldera
                    y el tubo
                  </div>
                </Collapse>
              </div>
              <div className="faq">
                <div id="headingTwo">
                  <h5 class="mb-0">
                    <button
                      onClick={() =>
                        setQuestion({ ...question, segunda: !question.segunda })
                      }
                      className="btn colorButtonfaq"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      La estructura es metálica ¿Se oxida?
                    </button>
                  </h5>
                </div>
                <Collapse
                  isOpen={question.segunda}
                  class="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    Todas nuestras tinas pasan por un proceso de galvanizado, lo
                    que garantiza una estructura libre de óxido. Si por algún
                    motivo o daño del producto se presenta óxido, se debe
                    aplicar galvanizado en frío o pintura antióxido.
                  </div>
                </Collapse>
              </div>
              <div className="faq">
                <div id="headingTwo">
                  <h5 class="mb-0">
                    <button
                      onClick={() =>
                        setQuestion({ ...question, tercera: !question.tercera })
                      }
                      className="btn colorButtonfaq"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      ¿Cuándo debo volver a pintar la estructura y la madera?
                    </button>
                  </h5>
                </div>
                <Collapse
                  isOpen={question.tercera}
                  class="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    La pintura de la estructura metálica tiene una duración de 5
                    años y la pintura de la madera 2 años. Recomendamos pintar
                    la estructura exterior con un fin netamente estético de tu
                    tina y el buen contraste con el entorno, también
                    recomendamos pintar la madera para protegerla y conservarla
                    en buen estado evitando que se pudran.
                  </div>
                </Collapse>
              </div>
              <div className="faq">
                <div id="headingTwo">
                  <h5 class="mb-0">
                    <button
                      onClick={() =>
                        setQuestion({ ...question, cuarta: !question.cuarta })
                      }
                      className="btn colorButtonfaq"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      ¿Cuándo debo volver a pintar la estructura y la madera?
                    </button>
                  </h5>
                </div>
                <Collapse
                  isOpen={question.cuarta}
                  class="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    Utilizamos pintura de gran calidad, la pintura de la
                    estructura metálica tiene una duración de 5 años y la
                    pintura de la madera 2 años. Recomendamos pintar la
                    estructura exterior con un fin netamente estético de tu tina
                    y el buen contraste con el entorno, también recomendamos
                    pintar la madera para protegerla y conservarla en buen
                    estado evitando que se pudran.
                  </div>
                </Collapse>
              </div>
              <div className="faq">
                <div id="headingTwo">
                  <h5 class="mb-0">
                    <button
                      onClick={() =>
                        setQuestion({ ...question, quita: !question.quita })
                      }
                      className="btn colorButtonfaq"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      ¿Y el despacho?
                    </button>
                  </h5>
                </div>
                <Collapse
                  isOpen={question.quita}
                  class="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    También despachamos a todo Chile. Dentro de la provincia de
                    Concepción el despacho es totalmente gratuito. Los
                    requisitos son disponer de una superficie sólida y lisa y
                    tener un acceso amplio, tanto para el ingreso de un camión
                    3/4 y la descarga de la o las tinas.
                  </div>
                </Collapse>
              </div>
              <div className="faq">
                <div id="headingTwo">
                  <h5 class="mb-0">
                    <button
                      onClick={() =>
                        setQuestion({ ...question, sexta: !question.sexta })
                      }
                      className="btn colorButtonfaq"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      ¿Cuánto tarda una tina Puritama si la reservo hoy?
                    </button>
                  </h5>
                </div>
                <Collapse
                  isOpen={question.sexta}
                  class="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    Éste factor dependerá del stock de insumos en el mercado y
                    la terminación que escojas. Si no existe dilatación con lo
                    anterior, la estimación normal de fabricación es de un mes
                    aproximadamente. Sin embargo, también solemos contar con
                    stock inmediato de tinas.
                  </div>
                </Collapse>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Faq;
