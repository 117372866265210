import React, {Component} from "react"
import {Col, Container, Row} from "reactstrap"

import Config from "../assets/images/icons/config.png"
import Honor from "../assets/images/icons/honor.png"
import Confianza from "../assets/images/icons/confianza.png"
export default class Services extends Component {
    constructor(props) {
        super(props)
        this.state = {
            services: [
                {
                    id: "service1",
                    icon: Config,
                    title: "Libre de mantenciones",
                    description: "Estructura completamente sellada, libre de hongos y filtraciones.",
                },
                {
                    id: "service2",
                    icon: Honor,
                    title: "Eficiencia",
                    description:
                        "Tinas de acero industrial galvanizado que garantizan una larga duración. Utilizamos materia prima e insumos de alta calidad.",
                },
                {
                    id: "service3",
                    icon: Confianza,
                    title: "Gran durabilidad",
                    description:
                        "Nuestras calderas poseen pasos de agua que permiten el aprovechamiento calórico y un calentamiento en menor tiempo.",
                },
            ],
            counters: [
                {
                    id: "1",
                    extraclass: "",
                    start: 25,
                    end: 49,
                    title: "Eficiencia",
                    description: "Estructura completamente sellada, libre de hongos y filtraciones.",
                },
                {
                    id: "2",
                    extraclass: "pt-3",
                    start: 25,
                    end: 76,
                    title: "Gran durabilidad",
                    description:
                        "Tinas de acero industrial galvanizado que garantizan una larga duración. Utilizamos materia prima e insumos de alta calidad.",
                },
                {
                    id: "3",
                    extraclass: "pt-3",
                    start: 25,
                    end: 99,
                    title: "Eficiencia",
                    description:
                        "Nuestras calderas poseen pasos de agua que permiten el aprovechamiento calórico y un calentamiento en menor tiempo.",
                },
            ],
        }
    }

    render() {
        return (
            <React.Fragment>
                <section className="section" id="services">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <div className="title-box text-center">
                                    <h3 className="title-heading mt-2">Aparte de fabricarlas con dedicación</h3>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-2 pt-4">
                            {/* Render Footer Link */}
                            {this.state.services.map((item, key) => (
                                <Col lg={4} key={key}>
                                    <div className="services-box p-3 mt-4 bg-red">
                                        <div className=" services-icon">
                                            <img src={item.icon} className="img-fluid" alt="extra-imagen" />
                                        </div>
                                        <h5 className="mt-5">{item.title}</h5>
                                        <p className="text-muted mt-3">{item.description}</p>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        )
    }
}
