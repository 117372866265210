import React, {Component} from "react"
import {Container, Row, Col} from "reactstrap"
import {Link} from "react-router-dom"
import Slider from "react-slick"

import Background1 from "../../assets/images/bg-0.png"
import Background2 from "../../assets/images/bg-10.jpg"
import Background3 from "../../assets/images/bg-9.jpg"

function SampleNextArrow(props) {
    const {onClick} = props
    return (
        <React.Fragment>
            <Link className="carousel-control-next" to="#" role="button" data-slide="next" onClick={onClick}>
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Siguiente</span>
            </Link>
        </React.Fragment>
    )
}

function SamplePrevArrow(props) {
    const {onClick} = props
    return (
        <React.Fragment>
            <Link className="carousel-control-prev" to="#" role="button" data-slide="prev" onClick={onClick}>
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Anterior</span>
            </Link>
        </React.Fragment>
    )
}

class Section extends Component {
    constructor(props) {
        super(props)
        this.state = {
            slideitems: [
                {
                    id: 100,
                    img: Background1,
                },
                {
                    id: 101,
                    img: Background2,
                },
                {
                    id: 103,
                    img: Background3,
                },
            ],
        }
    }

    render() {
        var settings = {
            autoplay: true,
            dots: true,
            speed: 100,
            infinite: true,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
        }

        const slides = this.state.slideitems.map((item, key) => {
            return (
                <div className="carousel-item" key={key}>
                    <div
                        style={{
                            backgroundImage: `url(${item.img})`,
                            backgroundPosition: "center center",
                            backgroundSize: "cover",
                            height: "100vh",
                        }}>
                        <div className="bg-overlay"></div>
                        <div className="home-center">
                            <div className="home-desc-center">
                                <Container>
                                    <Row className="align-items-center justify-content-center">
                                        <Col lg="10">
                                            <div className="home-content text-center text-white">
                                                <h1 className="home-title">Mereces un relajo excepcional</h1>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })

        return (
            <React.Fragment>
                <section className="home-slider" id="home">
                    <Container fluid>
                        <Row>
                            <div id="carouselExampleControls" className="carousel slide">
                                <div className="carousel-inner">
                                    <Slider {...settings}>{slides}</Slider>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        )
    }
}

export default Section
