import React, {useState} from "react"
import {Col, Container, Row} from "reactstrap"
import Baltinache from "../assets/images/tinajas/baltinache.png"
import Licanbur from "../assets/images/tinajas/licancabur.png"
import Plus from "../assets/images/icons/plus1.png"
import Tatio from "../assets/images/tinajas/tatio.png"
import useWindowDimensions from "../hooks/useWindowDimensions"

const extra = [
    {
        index: "extra-1",
        title: "Iluminación",
        description: [
            {
                index: "sub-01",
                title: "+ Relájate y estimula tus sentidos visuales.",
            },
            {
                index: "sub-02",
                title: "+ Podrás seleccionar un color estático o cambio dinámico.",
            },
        ],
    },
]

const termination = [
    {
        title: "Estándar",
        button: "Añadir gratis",
        description: [
            {index: "e-01", title: "+ Pintado de madera"},
            {index: "e-02", title: "+ Pintado metálico uniforme"},
        ],
    },
    {
        title: "Madera",
        button: "Añadir terminación exclusiva",
        description: [
            {index: "m-01", title: "+ Pintado de madera"},
            {index: "m-02", title: "+ Pintado metálico uniforme"},
            {index: "m-03", title: "+ Recubrimiento perimetral con madera"},
        ],
    },
    {
        title: "Obra de arte",
        button: "Añadir terminación exclusiva",
        description: [
            {index: "a-01", title: "+ Pintado de madera"},
            {index: "a-02", title: "+ Arte único en perímetro exterior"},
        ],
    },
]

const pricing = [
    {
        id: 1,
        title: "Tatio",
        descriptionCapacity: "Capacidad: 2-4 personas",
        descripcionTime: "Tiempo de preparación: 2 horas",
        descriptionDimension: "Dimensiones: 1,4x2,4x1 m",
        descriptionPeso: "Peso: 180kg",
        icon: Tatio,
        titlePrimary: false,

        pricingTag: false,
        child: [
            {
                btitle: "Unlimited",
                title: "Target Audience",
                icon: "mdi-checkbox-marked-circle text-success",
            },
            {
                btitle: "1",
                title: "User Account",
                icon: "mdi-checkbox-marked-circle text-success",
            },
            {
                btitle: "100+",
                title: "Video Tuts",
                icon: "mdi-close-circle text-danger",
            },
            {
                btitle: "Public",
                title: "Displays",
                icon: "mdi-close-circle text-danger",
            },
        ],
    },
    {
        id: 2,
        title: "Baltinache",
        titlePrimary: false,
        descriptionCapacity: "Capacidad: 4-6 personas",
        descripcionTime: "Tiempo de preparación: 3 horas",
        descriptionDimension: "Dimensiones: 2x2x1 m",
        descriptionPeso: "Peso: 200kg",
        icon: Licanbur,

        pricingTag: false,
        child: [
            {
                btitle: "Unlimited",
                title: "Target Audience",
                icon: "mdi-checkbox-marked-circle text-success",
            },
            {
                btitle: "1",
                title: "User Account",
                icon: "mdi-checkbox-marked-circle text-success",
            },
            {
                btitle: "100+",
                title: "Video Tuts",
                icon: "mdi-close-circle text-success",
            },
            {
                btitle: "Public",
                title: "Displays",
                icon: "mdi-close-circle text-danger",
            },
        ],
    },
    {
        id: 3,
        title: "Licancabur",
        descriptionCapacity: "Capacidad: 8-10 personas",
        descripcionTime: "Tiempo de preparación: 4 horas",
        descriptionDimension: "Dimensiones: 2,2x2,3x1 m",
        descriptionPeso: "Peso: 230 kg",
        titlePrimary: true,
        icon: Baltinache,
        pricingTag: true,
        child: [
            {
                btitle: "Unlimited",
                title: "Target Audience",
                icon: "mdi-checkbox-marked-circle text-success",
            },
            {
                btitle: "1",
                title: "User Account",
                icon: "mdi-checkbox-marked-circle text-success",
            },
            {
                btitle: "100+",
                title: "Video Tuts",
                icon: "mdi-close-circle text-success",
            },
            {
                btitle: "Public",
                title: "Displays",
                icon: "mdi-close-circle text-success",
            },
        ],
    },
]

const PricingComponent = ({setInformation, openCart, cotization, setCotization, ...props}) => {
    const {width} = useWindowDimensions()
    const [TinaSelected, setTinaSelected] = useState(null)
    const [terminationSelected, setTerminationSelected] = useState(null)
    const [extraSelected, setExtraSelected] = useState(null)

    const resetStates = () => {
        setTinaSelected(null)
        setTerminationSelected(null)
        setExtraSelected(null)
    }

    const isTinaSelected = ({item}) => {
        if (TinaSelected) {
            setTinaSelected(null)
        } else {
            goNextSection("selectTermination")
            setTinaSelected(item)
        }
    }

    const handlerTerminationSelected = (item) => {
        if (terminationSelected) {
            setTerminationSelected(null)
        } else {
            goNextSection("Extras")
            setTerminationSelected(item)
        }
    }
    const handlerExtraSelected = (item) => {
        if (extraSelected) {
            setExtraSelected(null)
        } else {
            setExtraSelected(item)
        }
    }

    const handlerCotization = () => {
        if (TinaSelected && terminationSelected) {
            setCotization([...cotization, [{tina: TinaSelected, terminationSelected, extraSelected}]])
            setInformation([...cotization, [{tina: TinaSelected, terminationSelected, extraSelected}]])
            resetStates()
            goNextSection("pricing")
        } else {
            alert("Por favor elija una tina y luego elija su terminación.")
        }
    }

    const scrollTo = (start, to, duration) => {
        let change = to - start,
            currentTime = 0,
            increment = 10

        let animateScroll = () => {
            currentTime += increment
            let val = easeInOutQuad(currentTime, start, change, duration)
            window.scrollTo(0, val)
            if (currentTime < duration) {
                setTimeout(animateScroll, increment)
            }
        }

        animateScroll()
    }

    const easeInOutQuad = (current_time, start, change, duration) => {
        current_time /= duration / 2
        if (current_time < 1) return (change / 2) * current_time * current_time + start
        current_time--
        return (-change / 2) * (current_time * (current_time - 2) - 1) + start
    }

    const goNextSection = (tagID) => {
        let scrollTargetPosition =
            document.getElementById(tagID).offsetTop -
            document.querySelector("div[data-nav='list']").scrollHeight -
            (width > 1012 ? (tagID === "pricing" ? -50 : 0) : 120)
        scrollTo(window.pageYOffset, scrollTargetPosition - 38, 300)
    }

    return (
        <React.Fragment>
            <section className="section bg-bluex  pt-5 pb-5" id="pricing">
                <Container>
                    <Row className="d-flex align-items-center justify-content-center">
                        <Col lg={12}>
                            <div>
                                <h2 className="text-center text-white text-uppercase font-weight-bold">
                                    ¡Te ayudamos a decidir!
                                </h2>
                                <h6 className="text-center text-uppercase  font-weight-bold text-white ">
                                    Todos nuestras tinas incluyen
                                </h6>
                                <Row className="d-flex justify-content-around pt-4">
                                    <Col
                                        md={3}
                                        xs={6}
                                        className="d-flex justify-content-center align-items-center mt-2">
                                        <span className=" btn btn-outline-info text-white rounded border-white div-border-radius ">
                                            Escalera segura
                                        </span>
                                    </Col>
                                    <Col
                                        md={3}
                                        xs={6}
                                        className="d-flex justify-content-center  align-items-center mt-2">
                                        <span className=" btn btn-outline-primary text-white btn-round rounded border-white div-border-radius ">
                                            Remo
                                        </span>
                                    </Col>
                                    <Col
                                        md={3}
                                        xs={6}
                                        className="d-flex justify-content-center align-items-center mt-2">
                                        <span className="btn btn-outline-primary text-white btn-round rounded border-white div-border-radius ">
                                            Posa vasos
                                        </span>
                                    </Col>
                                    <Col
                                        md={3}
                                        xs={6}
                                        className="d-flex justify-content-center align-items-center mt-2">
                                        <span className=" btn btn-outline-primary  text-white btn-round rounded border-white div-border-radius ">
                                            Llave de paso
                                        </span>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="section bg-gris">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="title-box text-center">
                                <h3 className="title-heading text-white">Escoge tu Tina Puritama</h3>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4 pt-4 d-flex align-items-center justify-content-center ">
                        {pricing.map((item, key) => (
                            <Col lg="4" key={key} className="flex align-items-center justify-content-center">
                                <div
                                    className={`${
                                        TinaSelected
                                            ? TinaSelected?.title === item?.title
                                                ? "pricing-box"
                                                : "pricing-box-disabled"
                                            : "pricing-box"
                                    }  mt-4 d-flex flex-column`}
                                    ß>
                                    {item.pricingTag && (
                                        <div className="pricing-badge">
                                            <span className="badge">Mas Vendida</span>{" "}
                                        </div>
                                    )}
                                    <div className="bg-red d-flex justify-content-center align-items-center">
                                        <img
                                            src={item.icon}
                                            className="img-fluid mb-3 "
                                            alt="logo-cotizacion-tinajas"
                                        />
                                    </div>
                                    {item.titlePrimary === true ? (
                                        <h2 className="text-primary text-center">{item.title}</h2>
                                    ) : (
                                        <h2 className="text-primary text-center">{item.title}</h2>
                                    )}

                                    <p className="pl-3 mt-4 pt-2 text-white description">{item.descriptionCapacity}</p>
                                    <p className="pl-3 text-white description">{item.descripcionTime}</p>
                                    <p className="pl-3 text-white description">{item.descriptionDimension}</p>
                                    <p className="pl-3 text-white description">{item.descriptionPeso}</p>
                                </div>
                                <button
                                    className="add"
                                    disabled={
                                        TinaSelected ? (TinaSelected.title !== item?.title ? true : false) : false
                                    }
                                    onClick={() => isTinaSelected({item})}>
                                    <div className="d-flex justify-content-center align-items-center pt-1 pb-1">
                                        <img
                                            src={Plus}
                                            className={`${
                                                TinaSelected
                                                    ? TinaSelected?.title === item?.title
                                                        ? "iconPlus-selected"
                                                        : "iconPlus"
                                                    : "iconPlus"
                                            } img-fluid pb-1`}
                                            alt="plus"
                                        />
                                        <span className="plan pl-1 text-white ">
                                            {TinaSelected?.title === item?.title ? "Quitar" : "Añadir"}
                                        </span>
                                    </div>
                                </button>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>
            <section id="selectTermination" className="section bg-gris mt-0 pt-0">
                <Container>
                    <h2 className="text-center text-white">Escoge tu terminación</h2>
                    <h6 className="text-center text-uppercase font-weight-bold text-white ">(Sólo una)</h6>
                    <Row className="d-flex align-items-center justify-content-center ">
                        {termination.map((item, key) => (
                            <Col lg="4" key={key} className="flex align-items-center justify-content-center">
                                <div
                                    className={`${
                                        terminationSelected
                                            ? terminationSelected?.title === item?.title
                                                ? "termination-box"
                                                : "termination-box-disabled"
                                            : "termination-box"
                                    }  mt-4 d-flex flex-column align-items-center`}>
                                    <div className="badge-termination mb-4">
                                        {item.titlePrimary === true ? (
                                            <p className="text-primary btn-type  ">{item.title}</p>
                                        ) : (
                                            <p className=" pb-0 btn-type">{item.title}</p>
                                        )}
                                    </div>

                                    <div>
                                        {item?.description.map((element, keys) => (
                                            <p className=" text-white description ml-0 pl-0 " key={keys}>
                                                {element.title}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                                <button
                                    className=" add  "
                                    disabled={
                                        terminationSelected
                                            ? terminationSelected.title !== item?.title
                                                ? true
                                                : false
                                            : false
                                    }
                                    onClick={() => handlerTerminationSelected(item)}>
                                    <div className="d-flex justify-content-center align-items-center pt-1 pb-1">
                                        <img
                                            src={Plus}
                                            className={`${
                                                terminationSelected
                                                    ? terminationSelected?.title === item?.title
                                                        ? "iconPlus-selected"
                                                        : "iconPlus"
                                                    : "iconPlus"
                                            } img-fluid pb-1`}
                                            alt="plus"
                                        />
                                        <span className="plan pl-1 text-white ">
                                            {terminationSelected?.title === item?.title ? "Quitar" : "Añadir"}
                                        </span>
                                    </div>
                                </button>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>

            <section id="Extras" className="section bg-gris mt-0 pt-0">
                <Container>
                    <h2 className="text-center text-white">Agrega a tu tina</h2>
                    <Row className="d-flex align-items-center justify-content-center ">
                        {extra.map((item, key) => (
                            <Col lg="6" key={key} className="flex align-items-center justify-content-center">
                                <div className="extra-box mt-4 d-flex flex-column align-items-center ">
                                    {item.titlePrimary === true ? (
                                        <h5 className="text-white  mb-4  ">{item.title}</h5>
                                    ) : (
                                        <h5 className="text-white mb-4 ">{item.title}</h5>
                                    )}

                                    <div>
                                        {item?.description.map((element, keys) => (
                                            <p className=" text-white description ml-0 pl-0 " key={keys}>
                                                {element.title}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                                <button className="extra" onClick={() => handlerExtraSelected(item)}>
                                    <div className="d-flex justify-content-center align-items-center pt-1 pb-1">
                                        <img
                                            src={Plus}
                                            className={`${
                                                extraSelected
                                                    ? extraSelected?.title === item?.title
                                                        ? "iconPlus-selected"
                                                        : "iconPlus"
                                                    : "iconPlus"
                                            } img-fluid pb-1`}
                                            alt="plus"
                                        />
                                        <span className="plan pl-1 text-white ">
                                            {extraSelected?.title === item?.title ? "Quitar" : "Añadir"}
                                        </span>
                                    </div>
                                </button>
                            </Col>
                        ))}
                    </Row>
                    <div className=" d-flex justify-content-center align-items-end mt-5  pt-5 ">
                        <Col lg="4" className="d-flex justify-content-center  ">
                            <button
                                onClick={handlerCotization}
                                type="button"
                                className="text-white pt-2 pb-2 pl-5 pr-5 btn btn-primary">
                                Agregar a la cotización
                            </button>
                        </Col>
                    </div>
                </Container>
            </section>
        </React.Fragment>
    )
}
export default PricingComponent
