import React, {useEffect, useState} from "react"
import "@coreui/coreui/dist/css/coreui.min.css"
import ReactGA from "react-ga4"
import Faq from "../../component/faq"
import Footer from "../../component/Footer/Footer"
import Navbar from "../../component/Navbar/NavBar"
import Pricing from "../../component/Pricing"
import Section from "./Section"
import Services from "../../component/Services"
import DrawerPay from "../../component/drawerPay"
import {Helmet} from "react-helmet"
import InView from "react-intersection-observer"

// Importing Section

const Layout4 = () => {
    const [navItems] = useState([
        {id: 4, idnm: "pricing", navheading: "Escoge tu tina"},
        {id: 5, idnm: "pricing", navheading: "cart"},
    ])
    const [pos] = useState(document.documentElement.scrollTop)
    const [imglight, setImgLight] = useState(true)
    const [navClass, setNavClass] = useState("navbar-light")
    const [fixTop] = useState(true)
    const [showDrawerCart, setShowDraweCart] = useState(false)
    const [infoCart, setInfoCart] = useState([])
    const [cotization, setCotization] = useState([])
    const [pricingInview, setPricingInView] = useState(false)
    const [footerInview, setFooterInView] = useState(false)
    useEffect(() => {
        ReactGA.initialize("G-YZDBP9YMS6")
        ReactGA.send({hitType: "pageview", page: "Home"})
    }, [])
    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true)

        return () => window.removeEventListener("scroll", scrollNavigation, true)
    })

    const HideDrawerCart = () => {
        setShowDraweCart(false)
    }
    const openCart = () => {
        setShowDraweCart(true)
    }
    const scrollNavigation = () => {
        var scrollup = document.documentElement.scrollTop
        if (scrollup > pos) {
            setNavClass("nav-sticky")
            setImgLight(true)
        } else {
            setNavClass("navbar-light")
            setImgLight(true)
        }
    }

    const setInformation = (cart) => {
        setInfoCart(cart)
        openCart()
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Tinas Puritamas | Tinajas Puritama | las mejores tinajas del sur de chile.</title>
                <meta
                    name="description"
                    content="  Tinas Puritama, compra de tinas calienta, mejores tinajas, tinajas de calidad, tinas biobio, tinajas
                    concepcion, tinajas, tinas, tinajas region del biobio, tinajas chile, compra de tinas."
                />
            </Helmet>
            {/* Importing Navbar */}
            <Navbar {...{navItems, navClass, imglight, setShowDraweCart, openCart, infoCart}} top={fixTop} />

            {/* Importing Section */}

            <Section itemscope itemtype="https://www.tinasputitama.cl/banner" />

            {/* Importing Service */}
            <Services itemscope itemtype="https://www.tinasputitama.cl/nuestros-servicios" />

            {/* Importing Pricing */}
            <InView as="div" onChange={(inView, entry) => setPricingInView(inView)}>
                <>
                    {pricingInview && (
                        <Helmet>
                            <title>Cotiza tinajas en Puritamas | Cotizando tinas caliente | Tinajas puritama </title>
                            <meta
                                name="description"
                                content="Cotizando tinajas puritama, cotizando tinas calientes, cotizando tinajas puritama, tinajas puritama fabricadas con amor, las mejores tinajas del sur de chile. "
                            />
                        </Helmet>
                    )}

                    <Pricing
                        {...{openCart, setInformation, cotization, setCotization}}
                        itemscope
                        itemtype="https://www.tinasputitama.cl/cotiza-con-nosotros"
                    />
                </>
            </InView>

            {/* Importing Contact Us */}
            {/* <Contact showInformation={showInformation} /> */}
            <Faq itemscope itemtype="https://www.tinasputitama.cl/preguntas-frecuentes" />
            <DrawerPay {...{showDrawerCart, HideDrawerCart, infoCart, setInfoCart, setCotization}} />
            {/* Importing Footer */}
            <InView as="div" onChange={(inView, entry) => setFooterInView(inView)}>
                <>
                    {footerInview && (
                        <Helmet>
                            <title>Contacto tinajas en Puritamas | Contactanos tinas caliente | Tinajas puritama</title>
                            <meta
                                name="description"
                                content="Contactanos tinajas puritama, contacto tinas calientes, contacto tinajas puritama, tinajas puritama fabricadas con amor, las mejores tinajas del sur de chile. "
                            />
                        </Helmet>
                    )}
                    <Footer itemscope itemtype="https://www.tinasputitama.cl/contactanos" />
                </>
            </InView>
        </React.Fragment>
    )
}
export default Layout4
